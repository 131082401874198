<template>
  <ContentBox :nav-list="navList">
    <div class="content">
      <div class="content-header">
        <h2 class="title">{{ data.title }}</h2>
        <div class="sub-info">
          <span>{{ $t('122cefe') }}：{{ data.createTime }}</span>
          <span>{{ $t('52c01f1') }}：{{ data.source }}</span>
        </div>
      </div>
      <div class="content-body">
        <img v-if="data.picture" :src="data.picture" alt="" />
        <div v-html="data.description"></div>
      </div>
    </div>
  </ContentBox>
</template>

<script>
import ContentBox from "@/components/ContentBox"
import api from "@/api/information.trends"

export default {
  name: "Information",
  components: {
    ContentBox,
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    navList() {
      return [
        { path: "/information/policy", name: this.$t('3a505c4'), i18n: 'information.name' },
        { path: "/information/trends", name: this.$t('01e7805'), i18n: 'information.service.name' },
        { path: "/information/trends/detail", name: this.$t('8c7d6cd'), i18n: 'information.name' },
      ]
    }
  },
  mounted() {
    const id = this.$route.query.id
    api.getDetail(id).then((res) => {
      this.data = res
    })
  },
  methods: {},
}
</script>

<style scoped lang="less">
.content {
  background: white;
  box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
}
.content-header {
  text-align: center;
  padding: 30px;
  border-bottom: 1px solid #eeeeee;
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
  }
  .sub-info {
    margin-top: 30px;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    span {
      margin: 0 20px;
    }
  }
}
.content-body {
  padding: 30px;
  img {
    width: 100%;
    background-color: #eeeeee;
    margin: 20px 0;
  }
}
</style>
